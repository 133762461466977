import { Input } from "@material-ui/core";
import { isString } from "lodash";

export function isStringArray(obj) {
  return Array.isArray(obj) && obj.every((item) => typeof item === "string");
}

export function compareSearchFilter(input, valueToMatch) {
  if (input && isString(input) && valueToMatch && isString(valueToMatch)) {
    return valueToMatch?.toLowerCase()?.includes(input?.toLowerCase());
  } else {
    return false;
  }
}
