import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthProvider";
import auth from "../utils/auth";

const useUserContextData = () => {
  const [mrnCodingSystem, setMrnCodingSystem] = useState(null);
  const token = useContext(AuthContext);
  const [user, setUser] = useState(null);
  const [isAuth] = useState(auth.isAuthenticated(token));

  useEffect(() => {
    setUser(auth.user());
  }, [isAuth]);

  useEffect(() => {
    if (user?.userinfo?.mrnCodingSystem) {
      setMrnCodingSystem(user?.userinfo?.mrnCodingSystem);
    }
  }, [user]);

  return {
    mrnCodingSystem,
  };
};

export default useUserContextData;
