import React, { Fragment } from "react";
import DocumentsTable from "material-table";
import { ThemeProvider } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../../styles/viewer.module.css";
import { theme } from "../theme";
import { tableIcons } from "../table-icons";
import { CatalogUtil } from "../../../utils";
import { compareSearchFilter } from "../../../utils/string.utils";

const DocumentList = ({
  data,
  originalData,
  showDocument,
  columns = [
    {
      title: "Title",
      field: "title",
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Author",
      field: "author",
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Facility",
      field: "facilityName",
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Date",
      field: "date",
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Document Type",
      field: "type",
      customFilterAndSearch: (input, rowData) =>
        compareSearchFilter(input, CatalogUtil.formatDocTypeDisplay(rowData)),
      render: (rowData) => CatalogUtil.formatDocTypeDisplay(rowData),
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Format",
      field: "format",
      render: (rowData) => {
        return (
          <Tooltip title={rowData.contentType}>
            <div>
              <FontAwesomeIcon
                icon={rowData.format}
                size="2x"
                className={styles.typeIcon}
              />
            </div>
          </Tooltip>
        );
      },
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Data",
      field: "data",
      hidden: true,
    },
  ],
}) => {
  try {
    if (originalData) {
      if (data.error) {
        return (
          <ThemeProvider theme={theme}>
            <Container className={styles.errorContainer}>
              {data.error}
            </Container>
          </ThemeProvider>
        );
      } else {
        return (
          <Fragment>
            <ThemeProvider theme={theme}>
              <DocumentsTable
                title=""
                columns={columns}
                data={data}
                icons={tableIcons}
                onRowClick={(e, data) => showDocument(data)}
                options={{
                  emptyRowsWhenPaging: false,
                  maxBodyHeight: "100%",
                  padding: "dense",
                  pageSize: 10,
                  search: true,
                  sorting: true,
                }}
              />
            </ThemeProvider>
          </Fragment>
        );
      }
    } else {
      return (
        <ThemeProvider theme={theme}>
          <div>
            <CircularProgress />
          </div>
        </ThemeProvider>
      );
    }
  } catch (error) {
    return (
      <ThemeProvider theme={theme}>
        <Container className={styles.errorContainer}>{error.message}</Container>
      </ThemeProvider>
    );
  }
};

export default DocumentList;
