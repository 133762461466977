import React, { Fragment } from "react";
import { Button, createMuiTheme, Grid, ThemeProvider } from "@material-ui/core";
import auth from "../../../utils/auth";
import styles from "../../../styles/viewer.module.css";
import colors from "../../../utils/colors";

const theme = createMuiTheme({
  overrides: {
    MuiChip: {
      label: {
        color: colors.gray01,
        zIndex: "1",
      },
      root: {
        backgroundColor: colors.baseBlue,
        color: colors.gray01,
        fontSize: "11px",
        height: "14px",
        margin: "-2px 2px",
        padding: "10px",
        verticalAlign: "initial",
      },
      deleteIcon: {
        color: colors.gray01,
        height: "15px",
        width: "15px",
      },
    },
    MuiFormControl: {
      root: {
        display: "-webkit-box",
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.baseBlue,
        fontSize: "12px",
        opacity: "0.4",
        paddingLeft: "15px",
        zIndex: "1",
      },
    },
    MuiInput: {
      root: {
        position: "absolute !important",
        top: "0",
        left: "0",
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: colors.gray01,
        borderRadius: "5px",
        width: "75%",
      },
    },
    MuiInputLabel: {
      shrink: {
        color: "transparent !important",
      },
    },
    MuiPaper: {
      root: {
        //width: '80%'
      },
    },
    MuiSelect: {
      select: {
        paddingLeft: "5px",
        "&:focus": {
          background: colors.primaryColor,
        },
      },
    },
  },
});

export const AssessmentsActions = ({ children }) => {
  return (
    // <Grid container spacing={0} className={styles.fhirLeftButtonContainer}>
    //   {children}
    // </Grid>
    <Fragment>{children}</Fragment>
  );
};

export const AssessmentsActionButton = ({
  text,
  action,
  canShow,
  assessment,
  patientInfo,
  color,
}) => {
  if (!assessment || (assessment && canShow(auth, assessment, patientInfo))) {
    return (
      <ThemeProvider theme={theme}>
        <div className={styles.documentTypesContainer}>
          <span className={styles.searchDirectoryButtonContainer}>
            <Button
              variant="contained"
              color={color || "primary"}
              onClick={action}
            >
              {text}
            </Button>
          </span>
        </div>
      </ThemeProvider>
    );
  }
  return null;
};
