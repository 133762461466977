import { TenantConfigurationStore } from "../components/Tenants/TenantConfigurationStore";
import { AxiosBackendHelper } from "../utils/AxiosHelper";
import { ErrorUtils } from "../utils/exceptions";
import { default as axiosFhirInstance } from "../utils/FhirAxiosHelper";

const axiosInstance = ErrorUtils.configureAxiosForMintErrors(
  AxiosBackendHelper.newInstance()
);

export default class AssessmentsAPI {
  static baseFhirUrl = null;

  static async setBaseUrl() {
    const tenantConf = await TenantConfigurationStore.getTenantConfiguration();
    if (tenantConf?.fhir?.fhirBaseUrl) {
      this.baseFhirUrl = tenantConf?.fhir?.fhirBaseUrl;
    }
  }

  static async getBaseUrl() {
    if (!this.baseFhirUrl) {
      await this.setBaseUrl();
    }
    return this.baseFhirUrl;
  }

  static async getAvailableAssessments() {
    try {
      const url = `/api/assessments/available?category=prapare`;
      const result = await axiosInstance.get(url);
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async getAssessments(query) {
    try {
      const url = `${await this.getBaseUrl()}/DocumentReference${query}`;
      const result = await axiosFhirInstance.get(url);
      const found = result?.data?.entry?.map((r) => r?.resource);
      if (found && found.length > 0) {
        return found;
      }
    } catch (error) {
      console.log(error);
    }
    return [];
  }
}
