import React, { useContext, useEffect, useState } from "react";
import FhirReferralDocumentList from "./FhirReferralDocumentList";
import { DocumentUtils } from "../../../services";
import { Context } from "../../../store/AppStore";
import { getReferralPatientId } from "../../../services/FhirReferralStates";
import { DocumentCatalogsStore } from "../Documents/DocumentCatalogsStore";
import { useHistory, useLocation } from "react-router-dom";
import { isString } from "lodash";

export default function FhirReferralDocuments({
  referral,
  documents,
  patientId,
  onDocumentDelete,
}) {
  const history = useHistory();
  const dispatch = useContext(Context)[1];
  const [catalogs, setCatalogs] = useState(null);
  let location = useLocation();

  useEffect(() => {
    (async () => {
      setCatalogs(await DocumentCatalogsStore.getDocumentCatalogs());
    })();
  });

  const handleDocument = async (data) => {
    if (data) {
      dispatch({ type: "UPDATE_DOCUMENT_DATA", payload: data });

      let redirectUrl = "";

      if (location && location?.pathname && isString(location?.pathname)) {
        if (location.pathname.includes("/advanced-referrals/")) {
          redirectUrl = `/patient/search/${
            patientId || getReferralPatientId(referral)
          }/info/1/advanced-referrals/${referral.id}/file/${data.document.id}/${
            data.iconName || "file-alt"
          }`;
        } else {
          redirectUrl = `/patient/search/${
            patientId || getReferralPatientId(referral)
          }/info/1/referrals/${referral.id}/file/${data.document.id}/${
            data.iconName || "file-alt"
          }`;
        }
      }

      if (redirectUrl) {
        history.push(redirectUrl);
      }
    }
  };

  if (!catalogs) {
    return null;
  }

  return (
    <>
      <h1
        style={{
          marginTop: "20px",
          marginBottom: "10px",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        Documents
      </h1>
      <FhirReferralDocumentList
        data={documents.map((document) =>
          DocumentUtils.getDisplayInfo(document, catalogs)
        )}
        showDocument={handleDocument}
        onDocumentDelete={onDocumentDelete}
      />
    </>
  );
}
