import React, { Fragment } from "react";
import { statusRenderer } from "../value-renderers";
import { ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { theme } from "../theme";
import { tableIcons } from "../table-icons";
import { urgentStyle } from "./urgentStyle";
import ReferralsTable from "material-table";
import { getDateTime, getDescription, getReferee, getReferrer } from "./utils";
import { compareSearchFilter } from "../../../utils/string.utils";

const FhirReferralsList = ({
  showAllReferrals,
  referrals,
  onReferralClick,
}) => {
  const columns = [
    {
      title: "Urgent?",
      customFilterAndSearch: (input, rowData) =>
        compareSearchFilter(input, rowData?.urgent ? "YES" : "NO"),
      render: (rowData) => {
        const { urgent } = rowData;
        return <div style={urgentStyle(urgent)}>{urgent ? "YES" : "NO"}</div>;
      },
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Referrer",
      customFilterAndSearch: (input, rowData) =>
        compareSearchFilter(input, getReferrer(rowData)?.display),
      render: (rowData) => <div>{getReferrer(rowData)?.display}</div>,
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Referee",
      customFilterAndSearch: (input, rowData) =>
        compareSearchFilter(input, getReferee(rowData)?.display),
      render: (rowData) => <div>{getReferee(rowData)?.display}</div>,
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Status",
      customFilterAndSearch: (input, rowData) =>
        compareSearchFilter(input, rowData?.status),
      render: (rowData) => <div>{statusRenderer(rowData.status, rowData)}</div>,
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Comment",
      customFilterAndSearch: (input, rowData) =>
        compareSearchFilter(input, rowData?.statusComment),
      render: (rowData) => <div>{rowData.statusComment || "-----"}</div>,
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Date/Time",
      customFilterAndSearch: (input, rowData) =>
        compareSearchFilter(input, getDateTime(rowData)),
      render: (rowData) => <div>{getDateTime(rowData)}</div>,
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
  ];

  columns.splice(columns.length - 1, 0, {
    title: "Description",
    customFilterAndSearch: (input, rowData) =>
      compareSearchFilter(input, getDescription(rowData)),
    render: (rowData) => <div>{getDescription(rowData)}</div>,
    cellStyle: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
  });

  if (referrals) {
    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <ReferralsTable
            title={""}
            columns={columns}
            data={referrals}
            icons={tableIcons}
            onRowClick={(e, referral) => onReferralClick(referral)}
            options={{
              emptyRowsWhenPaging: true,
              minBodyHeight: "100%",
              maxBodyHeight: "100%",
              padding: "dense",
              pageSize: 10,
              search: true,
            }}
          />
        </ThemeProvider>
      </Fragment>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <div>
          <CircularProgress />
        </div>
      </ThemeProvider>
    );
  }
};

export default FhirReferralsList;
